import { useRef, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import VehicleTypeModalOnSellSentry from "@/components/buy/modals/VehicleTypeModalOnSellSentry";
import StateListModal from "@/components/modals/StateListModal";
import { checkNativePage } from "@/libs/utils";
import cn from "classnames";
import {
  PlateVinFormType,
  usePlateVinEntryForm,
} from "@/hooks/usePlateVinEntryForm";
import VintageEntryModal from "./VintageEntryModal";

interface Props {
  data?: any;
}

const LicensePlateAndVinSection = ({}: Props) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const btnRef3 = useRef<HTMLButtonElement>(null);
  const {
    form,
    changeType,
    changeState,
    isVinShort,
    handleSubmit,
    isBadReq,
    isStateModalOpen,
    showStateModal,
    openVintageVehicleModal,
    setIsOpenVintageVehicleModal,
  } = usePlateVinEntryForm();
  const {
    register,
    formState: { errors },
    watch,
  } = form;
  const selectedType = watch("type");
  const selectedState = watch("state");
  const currentVin = watch("vin");
  const isVin = selectedType === PlateVinFormType.Vin;

  return (
    <div className="w-[90%] xs:w-[434px] bg-white border border-[#C3C3C3]  px-8 xs:pl-[65px] xs:pr-[75px] pt-[70px] pb-[80px] mt-8 md:mt-[76px] rounded-lg mx-auto">
      <div className="w-full flex text-sm bg-[#00B3DE26] mb-7 rounded-full relative">
        <div
          className="absolute top-1 bottom-1 w-1/2 bg-white rounded-full transition-all duration-300"
          style={{ left: isVin ? "49%" : "4px" }}
        />
        <div
          className={`w-1/2 text-center py-2 z-10 cursor-pointer transition-colors duration-300 text-black`}
          onClick={() => {
            changeType(PlateVinFormType.Plate);
          }}
        >
          License plate
        </div>
        <div
          className={`w-1/2 text-center py-2 z-10 cursor-pointer transition-colors duration-300 text-black
      `}
          onClick={() => {
            changeType(PlateVinFormType.Vin);
          }}
        >
          VIN
        </div>
      </div>
      {isVin ? (
        <div className="w-full">
          <div className="flex justify-center mt-4">
            <div className="relative w-full z-0">
              <input
                type="text"
                className={`form-control block w-full px-3 py-[6px] text-base font-medium bg-white border border-solid ${
                  isBadReq || Boolean(errors.vin) || isVinShort
                    ? "border-[#d80808] focus:border-[#d80808]"
                    : "border-[#9797aa] focus:outline focus:outline-[#cbced4] focus:border-black"
                } rounded m-0 focus:outline-4 transition-all`}
                placeholder="Enter VIN..."
                {...register("vin")}
              />
              {(isVinShort || isBadReq || Boolean(errors.vin)) && (
                <Image
                  width={12}
                  height={12}
                  className="absolute top-[11px] right-[10px] w-[20px] z-40"
                  src="/assets/invalid.svg"
                  alt="invalid"
                />
              )}
            </div>
          </div>

          <div className="w-full flex justify-center">
            <button
              className="w-full bg-[#00B3DE] hover:opacity-80 border border-[#00A0C7] text-white text-base font-bold py-2 px-4 rounded cursor-pointer mt-4"
              onClick={handleSubmit}
            >
              Get started
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="flex justify-center mt-4 gap-4">
            <div className="relative w-2/3">
              <input
                type="text"
                className={`form-control block w-full px-3 py-[6px] text-base font-medium bg-white border border-solid ${
                  isBadReq || Boolean(errors.plate)
                    ? "border-[#d80808] focus:border-[#d80808]"
                    : "border-[#9797aa] focus:outline focus:outline-[#cbced4] focus:border-black"
                } rounded m-0 focus:outline-4 transition-all`}
                placeholder="Enter License plate"
                {...register("plate")}
              />
            </div>
            <div className="w-1/3 relative">
              <button
                className={` form-control block w-full bg-white flex items-center justify-between text-base text-white font-normal py-[6px] px-3 border focus:border-2 ${
                  Boolean(errors.state)
                    ? "border-[#d80808]"
                    : "border-[#9797aa]"
                } border-solid focus:outline focus:border-[#00a0c7] focus:outline-[#bec3cd] focus:outline-4 transition-all rounded`}
                onClick={() => showStateModal()}
                ref={btnRef3}
              >
                <span className="text-base text-[#212529] font-medium truncate">
                  {selectedState == "" ? "" : selectedState}
                </span>
                <Image
                  width={10}
                  height={6}
                  src="/assets/expand_icon.svg"
                  alt="null"
                />
              </button>
              {isStateModalOpen && (
                <StateListModal
                  isStateModalOpened={isStateModalOpen}
                  setIsStateModalOpened={showStateModal}
                  setState={changeState}
                  btnRef={btnRef3}
                />
              )}
            </div>
          </div>
          <div className="w-full flex justify-center">
            <button
              className="w-full bg-[#00B3DE] hover:opacity-80 border border-[#00A0C7] text-white text-base font-bold py-2 px-4 rounded cursor-pointer mt-4"
              onClick={handleSubmit}
            >
              Get started
            </button>
          </div>
        </div>
      )}

      {openVintageVehicleModal && (
        <VintageEntryModal
          openVintageVehicleModal={openVintageVehicleModal}
          setOpenVintageVehicleModal={setIsOpenVintageVehicleModal}
          btnRef={btnRef}
          vin={currentVin ?? ""}
          state={selectedState}
        />
      )}
    </div>
  );
};

export default LicensePlateAndVinSection;
